import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["amount", "currency", "conversion"]

  connect() {
    this.updateConversion()
  }

  updateConversion() {
    const amount = parseFloat(this.amountTarget.value)
    const currency = this.currencyTarget.value

    if (currency === "CAD" && !isNaN(amount)) {
      this.fetchConversion(amount, currency)
    } else {
      this.conversionTarget.innerHTML = ""
    }
  }

  fetchConversion(amount, currency) {
    fetch(`/ofqj/convert_to_eur?amount=${amount}&currency=${currency}`)
      .then(response => response.json())
      .then(data => {
        this.conversionTarget.innerHTML = `Soit ${data.converted_amount}`
      })
      .catch(error => {
        console.error("Erreur lors de la conversion :", error)
      })
  }

  changeAmount() {
    this.updateConversion()
  }

  changeCurrency() {
    this.updateConversion()
  }
}
